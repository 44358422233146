function encrypt(text) {
	return [...text].map(c => String.fromCharCode(c.charCodeAt(0) + 1)).join("")
}
function unencrypt(text) {
	return "" + [...text].map(c => String.fromCharCode(c.charCodeAt(0) - 1)).join("")
}

const encryptedEls = document.querySelectorAll("[data-encrypted]")

export default function decrypt_elements() {
	console.log("hi")
	encryptedEls.forEach(el => {
		let attrs;
		try {
			attrs = JSON.parse(el.getAttribute("data-encrypted"))
		} catch(e) {
			return console.error("invalid json in data-encrypted\n", e)
		}
		for (let key in attrs) {
			console.log(key, attrs[key])
			el[key] = unencrypt(attrs[key])
		}
	})
}
